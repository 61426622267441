.contact-us-container{
    margin-top: 1.5rem;
    height: 223px;
    background: #439D8E;
    border-radius: 22px;
    padding: 3rem;
}
.w-80{
    width: 80%;
}
.w-20{
    width: 20%;
}
@media (max-width: 576px) {
    .contact-us-container{
        height: fit-content;
        padding: 30px;
    }
}
