.dot{
    border-radius: 50%;
    margin-right: 5px;
}

.dot-container{
    height: 27px;
}
@media (max-width: 576px) {
    .dot-container{
        height: 21px;
    }
}
