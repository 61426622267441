.comment-container{
    background-color: #EEEBE4;
    border-radius: 15px;
    flex: 1;
}
.comment-lbl{
    font-family: Lato;
    font-size: 16px;
    line-height: 22px;
}
.name-lbl{
    font-family: Barlow;
    font-weight: bold;
    font-size: 17px;
    line-height: 133%;
}
.circle-dot{
    width: 22px;
    height: 22px;
    float: right;
    border-radius: 50%;
    background-color: #E96D25;
}
.circle-dot-small{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #E2A91C;
}

.org-lbl{
    font-family: Lato;
    font-size: 11px;
    line-height: 16px;
}

@media (max-width: 576px) {
    .circle-dot{
        width: 7px;
        height: 7px;
    }
    .circle-dot-small{
        width: 4px;
        height: 4px;
    }
}
