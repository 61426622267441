.what-is-cwp{

}
.what-is-cwp-img{
    width: 264px;
}

@media (max-width: 576px) {
    .what-is-cwp-img{
        width: 130px;
    }
}
