.form-container{
    padding: 32px 55px;
    background-color: #F1B61C;
    border-radius: 26px;
}
.circle-bg{
    width: 600px;
    height: 600px;
}

@media (max-width: 576px) {
    .circle-bg{
        width: 330px;
        height: 330px;
    }
    .form-container{
        padding: 32px;
    }
}
