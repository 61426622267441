.magnet-container{
    background-color: #439D8E;
    opacity: 0;
}
.magnet-container.hide-opacity{
    visibility: hidden;
    opacity: 0;
    height: 0;
    padding: 0;
    transition: height 0.25s, padding 0.25s, visibility 0.25s, opacity 0.25s linear;
}
.magnet-container.show-opacity{
    padding: 15px;
    height: fit-content;
    visibility: visible;
    opacity: 1;
    transition: height 1s, padding 1s, visibility 1s, opacity 1s linear;
}
