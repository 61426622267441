.FreeDownload{
    width: 100%;
    height: fit-content;
    padding: 20px;
    background: #F1B61C;
    border-radius: 22px;
}
.circle-free-download-bg{
    width: 300px;
    height: 300px;
    max-width: 50%;
    border-radius: 50%;
    background-color: #439D8E;
}
.corporate-container{
    width: 50%;
}

@media (max-width: 576px) {
    .circle-free-download-bg{
        width: 200px;
        height: 200px;
        max-width: 100%;
        border-radius: 50%;
        background-color: #439D8E;
    }
    .FreeDownload{
        width: 100%;
        height: fit-content;
        padding: 40px;
        background: #F1B61C;
        border-radius: 22px;
    }
    .corporate-container{
        margin-top: 20px;
        width: 100%;
    }
}
