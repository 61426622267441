.ContactPage{
    padding-bottom: 460px;
}
.contact-form{
    border-radius: 25px;
    box-shadow: 0px 0px 2px 2px lightgray;
    padding: 45px;
}

.form-label{
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #4A4F53;
}

@media (max-width: 576px) {
    .contact-form{
        padding: 35px;
    }
}
