.tech-container{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 35%;
    height: 0;
    padding-bottom: 23%;
    border-radius: 15px;
    background-color: #EEEBE4;
    margin-right: 0.8rem;
    margin-top: 0.8rem;
}
.tech-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10%;
    width: 100%;
    height: 100%;

    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
}
.tech-logo{
    height: 2.18rem;
}
.tech-content-container{
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 1%;
    border-radius: 15px;
    background-color: #439D8E;
}
.title-lbl{
    font-family: Lato;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 170%;
    color: #808080;
}
.subtitle-emp-lbl{
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 110%;
}

.lbl-word{
    font-family: Lato;
    font-style: normal;
    font-size: 0.7rem;
}

@media (max-width: 576px) {
    .tech-container {
        width: 8.3rem;
        height: 5.4rem;
    }
    .tech-logo{
        height: 1.5rem;
    }
    .tech-content{
        height: 5rem;
    }
    .subtitle-emp-lbl{
        font-size: 1.3rem;
    }
    .lbl-word{
        font-size: 0.5rem;
    }
}
