.post-container{
    padding: 5px;
    cursor: pointer;
}
.post-lbl{
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #757A7E;
}
