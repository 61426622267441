.different-card-container{
    margin-top: 20px;
}
.lif-different-logo{
    display: block;
    width: 326px;
}
.word-container{
    display: flex;
    flex-direction: row;
    margin-left: 10px;
}
.dot-legend{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    position: absolute;
    margin-top: 10px;
}

@media (max-width: 576px) {
    .lif-different-logo {
        width: 195px;
        max-width: 100%;
    }
    .word-container{
        margin-top: 10px;
        margin-left: 0px;
    }
}
