.address-container{
    width: 100%;
    padding: 15px;
    height: fit-content;
    background: #DE1E30;
    border-top-right-radius: 23px;
    border-top-left-radius: 23px;
}
.maps-container{
    height: 610px;
}

@media (max-width: 576px) {
    .maps-container{
        height: 180px;
    }
}
