.search-bar{
    margin-top: 20px;
    position: relative;
    width: 367px;
    max-width: 100%;
    height: 58px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 21px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 15px;
}
.search-input{
    position: relative;
    z-index: 9;
    width: 100%;
    height: 100%;
    outline: none;
    border: transparent;
    font-family: Barlow;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    align-items: center;
    color: #DE1E30;
}
