.column-container{
    display: flex;
    flex-direction: row;
}
.typing {
    position: relative;
}
.top-img{
    width: 82px;
    max-width: 100%;
}

.second-img{
    width: 155px;
    max-width: 100%;
}

.third-img{
    width: 111px;
    max-width: 100%;
}

.we-re-lif-lbl{
    font-family: Barlow;
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    font-weight: 900;
    line-height: 35px;
    width: 125px;
    height: 105px;
}


@media (max-width: 576px) {
}
