.progress-container{
    margin-top: 15px;
}
.progress-label{
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    color: #FFFFFF;
}
