.card-img{
    width: 212px;
    max-width: 100%;
}

@media (max-width: 576px) {
    .card-img{
        width: 173px;
    }
}
