.BlogPage{
    padding-bottom: 460px;
}
.blogs-title{
    margin-left: 1rem;
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
    display: flex;
    align-items: center;

    color: #4A4F53;
}
.posted-by{
    margin-left: 1rem;
    margin-top: 0.5rem;
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #666B70;
}
