.red-btn{
    margin: auto;
    padding: 1rem 4rem;
    width: fit-content;
    height: fit-content;
    background: #D23338;
    border-radius: 21px;
}
.lbl-btn{
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    align-items: center;
    color: #FFFFFF;
}
.learn-more-container{
    margin: 1rem;
    background: #F5F2EA;
    border-radius: 22px;
    transition: top ease 0.5s;
}
.learn-more-word-container{
    padding: 1rem 3.3rem;
}

.solution-image{
    border-top-right-radius: 22px;
    border-top-left-radius: 22px;
}
