.general-button{
    width: fit-content !important;
    padding: 0.25rem 1.75rem;
    border-radius: 20px;
    border: 1px solid #DE1E30;
    cursor: pointer;
}

.button-label{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    width: fit-content;
    margin: auto;
    align-items: center;
    text-align: center;
}
