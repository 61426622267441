.framework-container{
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 20px;
    width: 278px;
    height: 278px;
    border-radius: 50%;
    background-color: #F5F2EA;
}
.framework-content{
    height: 120px;
}
.framework-logo{
    height: 50px;
}
.content-container{
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #439D8E;
}

@media (max-width: 576px) {
    .framework-container {
        width: 170px;
        height: 170px;
    }
    .framework-logo{
        height: 41px;
    }
    .framework-content{
        height: 80px;
    }
}
