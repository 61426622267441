
.rounded-bg{
    width: 100%;
    height: 0;
    position: absolute;
    border-radius: 50%;
    padding-bottom: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(231, 222, 205, 0.2);
}

.top-circle{
    width: 100%;
    height: 360px;
}
.bottom-circle{
    width: 100%;
    height: 200px;
    margin-top: -20px;
}
.example-container{
    margin-top: -10px;
    margin-bottom: -10px;
    background-color: #41907B;
}
.example-root{
}

@media (max-width: 576px) {
    .rounded-bg {
        width: 16rem;
        height: 16rem;
        padding-bottom: 0%;
    }
}
