.dancing-img{
    max-width: 3.8rem;
    height: auto;
}

@media (max-width: 576px) {
    .dancing-img{
        max-width: 2rem;
    }
}
