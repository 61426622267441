.circle-green{
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
    background-color: #F1B61C;
}
.what-is-assesment-can-img{
    width: 534px;
    max-width: 100%;
    display: block;
}

.dot-grey{
    width: 8px;
    height: 8px;
    background-color: #439D8E;
    border-radius: 50%;
    margin-right: 10px;
}

.assesment-item{
    margin-top: 30px;
}
