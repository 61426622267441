.boost-containter{
    background-color: #E2A91C;
}
.boost-lbl{
    font-family: Barlow;
    font-size: 2.25rem;
    line-height: 120%;
}
.ml-button{
    margin-left: -1.8rem;
}

@media (max-width: 576px) {
    .boost-lbl{
        font-size: 1.4rem;
    }
    .ml-button{
        margin: auto;
    }
}
