.csr-title{
}
.get-fit-margin{
    margin-top: 180px;
}
@media (max-width: 576px) {
    .get-fit-margin{
        margin-top: 30px;
    }
}
