.tab-container{
    width: fit-content;
    height: fit-content;
    padding: 1rem 2.3rem;
    background: #F5F2EA;
    border-radius: 22px;
}
.tab-data{
}

.tab-label{
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    align-items: center;
    color: #4A4F53;
}
.tab-label-copy{
    font-size: 12px;
}

.tab-image{
    border-radius: 20px;
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
}

.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    color: black;
    display: block;
    padding: 0.5rem 1rem;
}
.nav-item{
}

.nav-link:hover, .nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
}

.nav-tabs {
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: 1.25rem;
    text-align: center;
    border-bottom: 1px solid transparent;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    border-radius: 22px;
    color: white;
    background-color: #439D8E;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
@media (max-width: 576px) {
    .content-padding{
        padding: 0.5rem !important;
    }
}
