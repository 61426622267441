.category-container{
    padding: 5px;
    border-top: 1px solid #E5E5E5;
    cursor: pointer;
}
.category-lbl{
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #757A7E;
}
