.children-container{
    display: flex;
    flex-direction: column;
    position: relative;
}
.small-steps-container{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: fit-content;
    height: fit-content;
}
.img-margin{
    margin-top: 5px;
}
