.blogs-container{
    position: relative;
}
.pagination-container{
    margin-top: 4%;
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
}
