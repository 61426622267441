.titleLbl{
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: 4.75rem;
    line-height: 100%;
    text-transform: uppercase;
    color: #23282C;
}
.subtitleLbl{
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3rem;
    line-height: 150%;
    color: #23282C;
    opacity: 0.6;
}
.logo-image{
    max-width: 54px;
    height: auto;
}
@media (max-width: 576px) {
    .titleLbl{
        font-size: 3rem;
    }
    .subtitleLbl{
        font-size: 1.1rem;
    }
    .logo-image{
        max-width: 25px;
        margin: auto;
        display: block;
    }
}
