.impact-card-container{
    margin-top: 20px;
}

.card-container{
    padding: 15px;
}
.card-image{
    height: 60px;
    display: block;
    margin: 10px auto;
}
.reduce-healthcare-cost{
    height: auto;
}

@media (max-width: 576px){
    .reduce-healthcare-cost{
        height: auto;
    }
}
