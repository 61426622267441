.partner-card{
    position: relative;
}
.partner-card-container{
    position: relative;
    margin-top: -10px;
    background-color: #F1B61C;
    padding-bottom: 460px;
}
.partner-image{
    margin-top: 20px;
    display: block;
    height: 45px;
}

.celebrate-label{
    font-family: Barlow;
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 125%;
    display: flex;
    color: #FFFFFF;
}

.wohoo-container{
    display: flex;
    flex-direction: column;
}
.celebrate-img{
    margin-top: -0.5rem;
}
@media (max-width: 576px){
    .partner-image{
        height: 35px;
    }
    .wohoo-container{
        display: flex;
        flex-direction: row;
    }
    .celebrate-label{
        font-size: 24px;
    }
    .celebrate-container{
        width: 70%;
    }
    .celebrate-img{
        margin-top: -1rem;
    }
}
