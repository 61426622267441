.UpperContent{
    position: relative;
    padding-top: 8%;
    margin-top: -80px;
    z-index: -1;
    width: 100%;
    height: 1200px;
}
.background-wave{
    position: absolute;
    top: 0;
    left: 0;
    height: 64vw;
    z-index: -3;
}
.female-image{
    width: 32vw;
    position: absolute;
    top: 7.5rem;
    right: 18.5rem;
    z-index: -2;
}
.upper-normal{
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    color: #FFFFFF;
}

.title{
    font-family: Barlow;
    font-style: normal;
    font-weight: 800;
    display: flex;
    color: #FFFFFF;
}
.medium{
    font-size: 2.2rem;
}
.large{
    font-size: 4rem;
}
.red{
    color: #DE1E30;
}
