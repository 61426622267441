.table-container {
    padding: 1.5rem;
    overflow-x: auto;
}

.program-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
}

.table-header {
    font-weight: bold;
    color: white;
}

.program-table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.program-table tbody tr:nth-child(even) {
    background-color: #ffffff;
}

.program-table th:first-child {
    background-color: white;
    color: black;
}

.program-table th:nth-child(2) {
    background-color: #449285;
}

.program-table th:nth-child(3) {
    background-color: #EC6D14;
}

.program-table th:nth-child(4) {
    background-color: #E4162F;
}

.program-table th:nth-child(5) {
    background-color: #808080;
}

.section-header {
    background-color: #F2B815 !important;
    color: white !important;
    font-weight: bold;
}

.program-table td,
.program-table th {
    border: 1px solid #ddd;
    padding: 1rem;
}

.program-header {
    background-color: #F2B815;
    color: white;
    font-weight: bold;
}

.program-table td {
    color: #333;
}

.program-table th,
.program-table td {
    vertical-align: middle;
}

.program-table .checkmark {
    color: green;
}

.program-table .cross {
    color: red;
}

.pricing {
    padding: 4rem 4rem 28rem;
}

.titleLbl {
    font-style: normal;
    font-weight: 800;
    font-size: 4.75rem;
    line-height: 100%;
    text-transform: uppercase;
}

.pricing-table-lbl {
    font-style: normal;
    font-weight: 800;
    font-size: 2.25rem;
}

.logo-image {
    max-width: 54px;
    height: auto;
}

@media (max-width: 576px) {
    .table-container {
        padding: 0.5rem;
    }

    .pricing-table-lbl {
        font-size: 3rem;
    }

    .subtitleLbl {
        font-size: 1.2rem;
    }

    .logo-image {
        max-width: 25px;
        margin: auto;
        display: block;
    }

    .pricing {
        padding: 0.25rem 0.25rem 28rem;
    }

    .program-table {
        font-size: 0.75rem;
    }

    .program-table th {
        padding: 0.15rem;
    }

    .program-table td {
        padding: 0.25rem;
    }
    .img-free-tracker{
        max-width: 80%;
    }

    .program-header th {
        writing-mode: vertical-rl; /* Rotates text vertically from bottom to top */
        transform: rotate(180deg); /* Flips text for proper orientation */
        text-align: center;
        padding: 0.25rem;
        white-space: nowrap;
    }
}
