.ThankyouPage{
    margin-top: 120px;
    padding-bottom: 460px;
}
.well-do-lbl{
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
}
.social-ty-img{
    margin-left: 10px;
    width: 69px;
    height: 71px;
    margin-top: 90px;
}

@media (max-width: 576px) {
    .well-do-lbl{
        font-size: 24px;
        line-height: 30px;
    }
    .ThankyouPage{
        margin-top: 20px;
    }
    .social-ty-img{
        margin-left: 10px;
        margin-top: 50px;
        width: 50px;
        height: 52px;
    }
}
