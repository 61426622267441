.App {
}
.wa-logo{
  z-index: 999;
  position: fixed;
  right: 5%;
  bottom: 5%;
}

.content-padding{
  padding: 2.5rem !important;
}

.max-width{
  width: 100%;
}
.cursor-pointer{
  cursor: pointer;
}
.max-height{
  height: 100%;
}
.vertical-auto{
  margin: auto 0;
}
.auto-margin{
  margin: auto;
}
.card-spacer{
  margin-top: 4.3rem;
}
.content-spacer{
  margin-top: 2rem;
}
.image{
  width: 100%;
  height: 100%;
}
.item-spacer{
  margin: 10px 0px;
}

.z-index-0{
  z-index: 0;
}

.page-title{
  font-family: Barlow;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  color: #FFFFFF;
}
.large-page-title{
  font-family: Barlow;
  font-size: 4rem;
  font-style: normal;
  font-weight: 800;
  color: #FFFFFF;
  line-height: 67px;
}

.very-large-page-title{
  font-family: Barlow;
  font-style: normal;
  font-weight: 900;
  font-size: 96px;
  line-height: 86%;
}

.card-title{
  font-family: Barlow;
  font-style: normal;
  font-weight: 800;
  font-size: 1.9rem;
  line-height: 30px;
  color: #FFFFFF;
}
.content-title{
  font-family: Barlow;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  color: #FFFFFF;
  line-height: 31px;
}
.content-label{
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  color: #FFFFFF;
  line-height: 27px;
}
.solution-title{
  font-family: Barlow;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 43px;
  color: #FFFFFF;
}
.large-page-title{
  font-family: Barlow;
  font-size: 4rem;
  font-style: normal;
  font-weight: 800;
  color: #FFFFFF;
  line-height: 67px;
}

.red-font-color{
  color: #DE1E30;
}
.black-font-color{
  color: black;
}

.column-container{
  display: flex;
  flex-direction: row;
}
.row-container{
  display: flex;
  flex-direction: column;
}

.semi-black-font-color{
  color: #4A4F53;
}

.grey-font-color{
  color: #439D8E;
}

.content-word-smaller{
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3rem;
  font-size: 0.9rem;
}
.no-padding{
  padding: 0;
}
.center-label{
  text-align: center;
}
.block-disp{
  display: block;
}
.label-align{
  text-align: left;
}
.margin-top-mobile{
  margin-top: 0;
}
.title-line-height{
  line-height: 55px;
}
.rounded-corner{
  border-radius: 21px !important;
}
.lif-black{
  color: #23282C !important;
}
.lif-tosca{
  color: #41907B !important;
}
.lif-green{
  color: #E96D25 !important;
}
.lif-white{
  color: #EEEBE4 !important;
}
.lif-grey{
  color: #808080 !important;
}
.lif-orange{
  color: #E96D25 !important;
}
.lif-cocrol{
  color: #EEEBE4 !important;
}
.lif-magenta{
  color: #D51B5C !important;
}
.lif-raspberry{
  color: #E4162F !important;
}

.general-form{
  height: 55px !important;
}
.title-margin{
  margin-top: 150px;
}
.padding-card{
  padding: 8rem 0px;
}
.justify-content-evenly{
  justify-content: space-evenly;
}
@media (max-width: 576px) {
  .margin-top-mobile{
    margin-top: 1.5rem;
  }
  .label-align{
    text-align: center;
  }
  .content-word-smaller{
    font-size: 0.6rem;
    line-height: 0.9rem;
  }
  .content-title{
    font-size: 1.2rem;
  }
  .card-title{
    font-size: 1.5rem;
  }
  .content-label{
    overflow-wrap: break-word;
    font-size: 1rem;
    line-height: 21px;
  }
  .page-title{
    font-size: 1.5rem;
  }
  .large-page-title{
    font-size: 36px;
    line-height: 37px;
  }
  .very-large-page-title{
    font-size: 64px;
  }
  .solution-title{
    font-size: 24px;
    line-height: 28px;
  }
  .title-line-height {
    line-height: 30px;
  }
  .title-margin{
    margin-top: 25px;
  }
  .justify-content-xs-center {
    justify-content: center !important;
  }
  .flex-column-xs-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-column-xs {
    flex-direction: column !important;
  }
  .padding-card{
    padding: 2.8rem 0px;
  }
}
