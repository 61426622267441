.guide-line-height{
    line-height: 76px;
}


@media (max-width: 576px) {
    .guide-line-height{
        line-height: 120%;
        margin-bottom: 1.5rem;
    }
}
