.trustedBy-lbl{
    font-family: Barlow;
    font-size: 1.3rem;
    line-height: 150%;
    text-align: center;
    color: #E96D25;
    opacity: 0.8;
}

.partner-lp-img{
    height: 45px;
    width: auto;
    margin: 1rem;
    display: block;
}

@media (max-width: 576px) {
    .trustedBy-lbl{
        font-size: 0.75rem;
    }
    .partner-lp-img{
        height: 33px;
        margin: 0.5rem;
    }
}

