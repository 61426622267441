.BlogsListCard{
    margin-top: 45px;
    margin-left: 10px;
    width: 446px;
    max-width: 48%;
    cursor: pointer;
}
.blog-category{
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    align-items: center;
    text-align: center;
    color: #757A7E;
}
.blog-title-list-card{
    padding-top: 7px;
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    align-items: center;
    text-align: center;

    color: #4A4F53;
}
.blogs-lbl{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 163%;
    /* or 23px */

    display: flex;
}
.blog-img-container{
    width: 446px;
    max-width: 100%;
    border-radius: 23px;
    overflow: hidden;
}
.description-container{
    margin-top: 5px;
    width: 236px;
    height: 100px;
    overflow: hidden;
}

.blog-content{
    padding: 15px;
    background: #FFFFFF;
    -webkit-box-shadow: 0 0 10px #EDEFF6;
    box-shadow: 0 0 10px #EDEFF6;
}
.read-more-lbl{
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 163%;
}

@media (max-width: 576px) {
    .BlogsListCard{
        width: 292px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .blog-img-container{
        width: 292px;
        max-width: 100%;
        border-radius: 23px;
        overflow: hidden;
    }
    .description-container{
        margin-top: 5px;
        width: 150px;
        height: 150px;
        overflow: hidden;
    }
}
