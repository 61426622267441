.footer{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin-top: -450px;
}
.footer-wave{
    width: 100%;
    height: 460px;
}
.footer-data{
    background-color: #F5F2EA;
    z-index: 1;
}
.footer-container{
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 15px;
    height: fit-content;
    background: #606060;
}
.footer-upper-container{
    padding: 15px 0;
    margin: auto;
}
.footer-upper-spacer{
    margin-top: 2rem;
}
.footer-upper-subtitle{
    margin-top: 12px;
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #303030;
    opacity: 0.6;
}

.footer-upper-title{
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    align-items: center;
    color: #303030;
    opacity: 0.8;
}

.footer-label{
    padding: 0px 10px;
    width: fit-content;
    color: white;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14pt;
}
@media (max-width: 576px){
    .footer-label{
        margin: auto;
    }
    .footer-wave{
        height: 200px;
    }
}
